import React, { Component } from "react";

class Titles extends Component {
  render() {
    return (
      <div className="Titles">
        <img className="logo" src={require("./logo.png")} alt="" />
        <ul className="title-item">
          <li>
            <h3>ARGO: Agent's Reply Generator and Optimizer</h3>
          </li>
        </ul>
      </div>
    );
  }
}

export default Titles;
