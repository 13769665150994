import React from "react";

class Message extends React.Component {
  render() {
    const uid = this.props.username;
    if (uid === 0) {
      this.state = {
        username: "Client"
      };
    }
    if (uid === 1) {
      this.state = {
        username: "Agent"
      };
    }
    return (
      <div className={"message" + "-" + this.state.username}>
        <div className={"message-username" + "-" + this.state.username}>
          {this.state.username}
        </div>
        <div className={"message-text" + "-" + this.state.username}>
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Message;
