import React, { Component } from "react";
import "./App.css";
import Titles from "./components/Titles";
import Feed from "./components/Feed";
import SendMessageForm from "./components/SendMessageForm";
import Recommendations from "./components/Recommendations";

class App extends Component {
  constructor() {
    super();
    this.state = {
      messages: [],
      testinglist: [],
      recLogs: "",
      message: "",
      theFlag: false,
      recFlag: false,
      apiFlag: false,
      reco: "",
      newreco: ""
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.resetFlag = this.resetFlag.bind(this);
    this.resetrecFlag = this.resetrecFlag.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  fetchData(currentMsg, messages) {
    var latestMsg = "";
    const url1 = "https://argo.ml.dhx.autodesk.com/document/";
    fetch(url1, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({ document: currentMsg }) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(json => {
        this.setState({
          reco: json.reply,
          recLogs: json.reply,
          recFlag: true
        });
      });
    const msgSize = messages.length;

    if (msgSize >= 2) {
      if (messages[msgSize - 1][0] === 0) {
        latestMsg =
          "Client: " + messages[msgSize - 1][1] + currentMsg + " Agent:";
      }
      if (messages[msgSize - 1][0] === 1) {
        latestMsg =
          "Agent: " +
          messages[msgSize - 1][1] +
          " Client: " +
          currentMsg +
          " Agent:";
      }
    } else {
      latestMsg = "Client: " + currentMsg + " Agent:";
      console.log("current msg:");
      console.log(latestMsg);
    }

    const url2 = "https://argo.ml.dhx.autodesk.com/getreply/";
    console.log(latestMsg);
    fetch(url2, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify({ document: latestMsg }) // body data type must match "Content-Type" header
    })
      .then(response => response.json())
      .then(json => {
        this.setState({
          newreco: json.reply,
          recFlag: true
        });
      });
  }

  sendMessage(uid, text) {
    this.setState({
      messages: [
        ...this.state.messages,
        [uid, text, "RECs:" + this.state.recLogs + "-+-"]
      ]
    });
    if (uid === 0) {
      this.fetchData(text, this.state.messages);
      this.setState({
        apiFlag: true
      });
    } else {
      this.setState({
        apiFlag: false,
        recFlag: false,
        recLogs: ""
      });
    }
  }

  changeMessage(newMessage) {
    this.setState({
      message: newMessage,
      theFlag: true
    });
  }
  handleClick() {
    const element = document.createElement("a");
    const file = new Blob([this.state.messages], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "Chat_Log.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  resetFlag() {
    this.setState({
      theFlag: false
    });
  }

  resetrecFlag() {
    this.setState({
      recFlag: false
    });
  }

  render() {
    return (
      <div className="App">
        <Titles />
        <Feed messages={this.state.messages} />
        <SendMessageForm
          sendMessage={this.sendMessage}
          senderId={1}
          thePosition={"send-message-form-agent"}
          theMessage={this.state.message}
          theFlag={this.state.theFlag}
          resetFlag={this.resetFlag}
        />
        <SendMessageForm
          sendMessage={this.sendMessage}
          senderId={0}
          thePosition={"send-message-form-client"}
          theMessage={this.state.message}
          theFlag={this.state.theFlag}
          resetFlag={this.resetFlag}
        />
        <Recommendations
          changeMessage={this.changeMessage}
          apiFlag={this.state.apiFlag}
          recFlag={this.state.recFlag}
          reco={this.state.reco}
          newreco={this.state.newreco}
          resetrecFlag={this.resetrecFlag}
        />
        <button onClick={this.handleClick} className="logchat">
          Generate Log
        </button>
      </div>
    );
  }
}

export default App;
