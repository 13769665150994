import React from "react";

class SendMessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      labelText: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setValue = this.setValue.bind(this);
  }
  componentDidMount() {
    if (this.props.senderId === 0) {
      this.setState({
        labelText: "Client: "
      });
    }
    if (this.props.senderId === 1) {
      this.setState({
        labelText: "Agent: "
      });
    }
  }
  handleChange(e) {
    e.preventDefault();
    if (this.props.theFlag) {
      this.props.resetFlag();
    }
    this.setState({
      message: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.message) {
      this.props.sendMessage(this.props.senderId, this.state.message);
      this.setState({
        message: ""
      });
    }
  }

  setValue(e) {
    if (
      this.props.theFlag &&
      this.props.thePosition === "send-message-form-agent"
    ) {
      return this.props.theMessage;
    } else {
      return this.state.message;
    }
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit} className={this.props.thePosition}>
        <input
          onChange={this.handleChange}
          onClick={this.handleChange}
          value={this.setValue()}
          placeholder={this.state.labelText + "Write and press ENTER"}
          type="text"
        />
      </form>
    );
  }
}

export default SendMessageForm;
