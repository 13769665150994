import React from "react";

class Recommendations extends React.Component {
  constructor() {
    super();
    this.state = {
      message: "",
      reco: "",
      newreco: "",
      rec1: "",
      rec2: "",
      rec3: "",
      rec4: "",
      tempFlag: true
    };
    this.handleClick1 = this.handleClick1.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClick3 = this.handleClick3.bind(this);
    this.handleClick4 = this.handleClick4.bind(this);
    this.sentenceCase = this.sentenceCase.bind(this);
  }
  handleClick1(e) {
    e.preventDefault();
    this.props.changeMessage(this.state.rec1);
  }
  handleClick2(e) {
    e.preventDefault();
    this.props.changeMessage(this.state.rec2);
  }
  handleClick3(e) {
    e.preventDefault();
    this.props.changeMessage(this.state.rec3);
  }
  handleClick4(e) {
    e.preventDefault();
    this.props.changeMessage(this.state.rec4);
  }
  sentenceCase(str) {
    str = str + ".";
    return str
      .toString()
      .replace(/(^|\. *)([a-z])/g, function(match, separator, char) {
        return separator + char.toUpperCase();
      });
  }
  componentDidUpdate() {
    if (this.props.recFlag) {
      var gptlist = [];
      gptlist = this.props.newreco;
      var reco1,
        reco2,
        reco3,
        reco4 = " ";
      if (gptlist[0]) {
        reco1 = gptlist[0].split("t:")[0];
        reco1 = reco1.replace("Clien", "").replace("Agen", "");
      }
      if (gptlist[1]) {
        reco2 = gptlist[1].split("t:")[0];
        reco2 = reco2.replace("Clien", "").replace("Agen", "");
        // reco2 = reco2[0].split("Client")[0];
      }
      if (gptlist[2]) {
        reco3 = gptlist[2].split("t:")[0];
        reco3 = reco3.replace("Clien", "").replace("Agen", "");
        // reco3 = reco3[0].split("Client")[0];
      }
      if (this.props.reco) {
        reco4 = this.sentenceCase(this.props.reco)
          .replace("..", ".")
          .replace("?.", "?")
          .replace("!.", "!")
          .replace(" .", ".")
          .replace(" ,", ",")
          .replace(" ?", "?")
          .replace(" !", "!")
          .replace(/ i /g, " I ");
      }
      this.setState({
        rec1: reco1,
        rec2: reco2,
        rec3: reco3,
        rec4: reco4
      });
      this.props.resetrecFlag();
    }
  }

  render() {
    if (this.props.apiFlag && this.props.newreco) {
      return (
        <div onLoad={this.fetchData} className="recommendations">
          <h4>Here are some recommendations:</h4>
          <div onLoad={this.handleClick}>
            <button onClick={this.handleClick1} className="button">
              {this.state.rec1}
            </button>
            <button onClick={this.handleClick2} className="button">
              {this.state.rec2}
            </button>
            <button onClick={this.handleClick3} className="button">
              {this.state.rec3}
            </button>
            <button onClick={this.handleClick4} className="button">
              {this.state.rec4}
            </button>
          </div>
        </div>
      );
    } else {
      return <div className="recommendations" />;
    }
  }
}
export default Recommendations;
