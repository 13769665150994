import React from "react";
import ReactDOM from "react-dom";
import Message from "./Message";
class Feed extends React.Component {
  componentDidUpdate() {
    const node = ReactDOM.findDOMNode(this);
    node.scrollTop = node.scrollHeight;
  }
  render() {
    return (
      <div className="feed">
        {this.props.messages.map((message, index) => {
          return (
            <Message key={index} username={message[0]} text={message[1]} />
          );
        })}
      </div>
    );
  }
}

export default Feed;
